var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"request-coaching-modal",attrs:{"id":"request-coaching-modal","footer-class":"modal-footer-class","centered":"","size":"lg","ok-title":_vm.$t('Submit'),"ok-only":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" ☝️ "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Answer the following questions in order for us to be able to understand your needs')))])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('required-field-explanation'),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.requestCoaching}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"request-coaching-modal-observer"},[_c('validation-provider',{attrs:{"name":"reasonForCoachingRequest","rules":"required|max:1400","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"reason-for-coaching-request"}},[_vm._v(" "+_vm._s(_vm.$t('For which professional issue do you want coaching on?'))+" "),_c('required-field')],1),_c('b-form-textarea',{attrs:{"id":"reason-for-coaching-request","rows":"3","no-resize":""},model:{value:(_vm.reasonForCoachingRequest),callback:function ($$v) {_vm.reasonForCoachingRequest=$$v},expression:"reasonForCoachingRequest"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"acceptTermsAndConditions","rules":{
            required: {
              allowFalse: false
            }
          },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.acceptTermsAndConditions),callback:function ($$v) {_vm.acceptTermsAndConditions=$$v},expression:"acceptTermsAndConditions"}},[_vm._v(" "+_vm._s(_vm.$t('I agree to'))+" "),_c('b-link',{attrs:{"to":{ name: 'mentoring-terms' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the terms and conditions of WHEN'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('and'))+" "),_c('b-link',{attrs:{"to":{ name: 'privacy-policy' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the privacy policy'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('as a candidate for coaching'))+" "),_c('required-field'),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }