<template>
  <div class="d-flex p-2 mb-2 make-request-div">
    <slot />
  </div>
</template>

<script>
import {

} from 'bootstrap-vue'

export default {
  components: {

  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
  .make-request-div {
    background: #8044701a;
  }

  .cta-link {
    text-decoration: underline;
  }
</style>
