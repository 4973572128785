<template>
  <div v-if="showCallToAction">
    <mentorship-call-to-action-message v-if="!isUserLoggedIn">
      <div class="mr-2">
        {{ $t('To make a request for mentorship:') }}
      </div>
      <div class="d-flex flex-column">
        <div>
          {{ $t('Do you already have a mentoring package?') }} <b-link
            class="cta-link"
            :to="{ name: 'auth-login' }"
          >
            {{ $t('MentorPageLogin') }}
          </b-link>
        </div>
        <div>
          {{ $t('Do not you have a mentoring package?') }} <b-link
            class="cta-link"
            :to="{ name: 'mentoring-packages' }"
          >
            {{ $t('MentorPageSeePackages') }}
          </b-link>
        </div>
      </div>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="!hasPackages">
      <div class="mr-2">
        {{ $t('You should purchase a package to be able to make a request for mentorship:') }}
      </div>
      <div class="d-flex flex-column">
        <div>
          <b-link
            class="cta-link"
            :to="{ name: 'mentoring-packages' }"
          >
            {{ $t('MentorPageSeePackages') }}
          </b-link>
        </div>
      </div>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="needsToFinishCoachingFirst">
      <template v-if="userPendingCoachingPackages.length">
        <b-card-text>
          {{ $t('You cannot make a new mentorship request because there is a pending one.') }}
        </b-card-text>
      </template>
      <template v-else-if="userPaidCoachingPackages.length && !mentor.is_available_for_coaching">
        <b-card-text>
          {{ $t('You cannot make a request because mentor is not available.') }}
        </b-card-text>
      </template>
      <template v-else-if="userPaidCoachingPackages.length && mentor.is_available_for_coaching && !mentor.is_coach">
        <b-card-text>
          {{ $t('You should complete coaching first.') }} <b-link
            class="cta-link"
            :to="{ name: 'our-mentors', query: { view: 'coaches' } }"
          >
            {{ $t('View all coaches here') }}
          </b-link>
        </b-card-text>
      </template>
      <template v-else-if="userPaidCoachingPackages.length && mentor.is_available_for_coaching && mentor.is_coach">
        <b-button
          v-if="canMakeARequestForCoaching"
          variant="primary"
          @click="requestCoaching"
        >
          {{ $t('Request for coaching') }}
        </b-button>
      </template>
      <template v-else-if="userAwaitingPaymentCoachingPackages.length && !mentor.is_available_for_coaching">
        <b-card-text>
          {{ $t('You should complete coaching first.') }} <b-link
            class="cta-link"
            :to="{ name: 'our-mentors', query: { view: 'coaches' } }"
          >
            {{ $t('View all coaches here') }}
          </b-link>
        </b-card-text>
      </template>
      <template v-else-if="userAwaitingPaymentCoachingPackages.length && mentor.is_available_for_coaching && !mentor.is_coach">
        <b-card-text>
          {{ $t('You should complete coaching first.') }} <b-link
            class="cta-link"
            :to="{ name: 'our-mentors', query: { view: 'coaches' } }"
          >
            {{ $t('View all coaches here') }}
          </b-link>
        </b-card-text>
      </template>
      <template v-else-if="userAwaitingPaymentCoachingPackages.length && mentor.is_available_for_coaching && mentor.is_coach">
        <b-card-text>
          {{ $t('When you complete your payment and after it is being confirmed, you will be able to make a request for mentorship.') }}
        </b-card-text>
      </template>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="mentor.is_mentor && userPendingMentoringPackages.length">
      <b-card-text>
        {{ $t('You cannot make a new mentorship request because there is a pending one.') }}
      </b-card-text>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="(mentor.is_mentor && !mentor.is_available_for_mentoring && userPaidMentoringPackages.length) || (mentor.is_coach && !mentor.is_available_for_coaching && userPaidCoachingPackages.length)">
      <b-card-text>
        {{ $t('You cannot make a request because mentor is not available.') }}
      </b-card-text>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="(mentor.is_mentor && userAwaitingPaymentMentoringPackages.length && !userPaidMentoringPackages.length) || (mentor.is_coach && userAwaitingPaymentCoachingPackages.length && !userPaidCoachingPackages.length)">
      <b-card-text>
        {{ $t('When you complete your payment and after it is being confirmed, you will be able to make a request for mentorship.') }}
      </b-card-text>
    </mentorship-call-to-action-message>

    <mentorship-call-to-action-message v-else-if="canMakeARequest">
      <b-button
        v-if="canMakeARequestForMentoring"
        variant="primary"
        class="mr-1"
        @click="requestMentoring"
      >
        {{ $t('Request for mentoring') }}
      </b-button>
      <b-button
        v-if="canMakeARequestForCoaching"
        variant="primary"
        @click="requestCoaching"
      >
        {{ $t('Request for coaching') }}
      </b-button>
    </mentorship-call-to-action-message>

    <request-mentoring-modal
      v-if="canMakeARequest"
      ref="request-mentoring-modal"
      :mentor="mentor"
    />
    <request-coaching-modal
      v-if="canMakeARequest"
      ref="request-coaching-modal"
      :mentor="mentor"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BLink,
} from 'bootstrap-vue'
import { useUserPackagesRepository } from '@mentoring-platform/composables'
import RequestCoachingModal from '@mentoring-platform/views/components/blocks/RequestCoachingModal.vue'
import RequestMentoringModal from '@mentoring-platform/views/components/blocks/RequestMentoringModal.vue'
import MentorshipCallToActionMessage from '@mentoring-platform/views/components/blocks/Mentor/MentorshipCallToActionMessage.vue'

export default {
  components: {
    BButton,
    BCardText,
    BLink,
    MentorshipCallToActionMessage,
    RequestCoachingModal,
    RequestMentoringModal,
  },
  props: {
    mentor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      packagesInfoRetrieved: false,
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['user/isUserLoggedIn']
    },
    showCallToAction() {
      return this.packagesInfoRetrieved
        // eslint-disable-next-line eqeqeq
        && this.userRunningMentoringPackages.length == 0
        // eslint-disable-next-line eqeqeq
        && this.userRunningCoachingPackages.length == 0
    },
    hasPackages() {
      return this.userPackages.length > 0
    },
    needsToFinishCoachingFirst() {
      if (this.userPendingCoachingPackages.length) {
        return true
      }
      if (this.userAwaitingPaymentCoachingPackages.length || this.userPaidCoachingPackages.length) {
        const sortFunction = (a, b) => {
          if (a.ended_at_date > b.ended_at_date) {
            return 1
          }

          return -1
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const sortedCompletedCoachingPackages = this.userCompletedCoachingPackages.sort(sortFunction)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const sortedCompletedMentoringPackages = this.userCompletedMentoringPackages.sort(sortFunction)
        if (sortedCompletedCoachingPackages.length === 0) {
          return true
        }
        if (sortedCompletedMentoringPackages.length === 0) {
          return false
        }

        const latestCoachingPackage = sortedCompletedCoachingPackages[sortedCompletedCoachingPackages.length - 1].ended_at_date
        const latestMentoringPackage = sortedCompletedMentoringPackages[sortedCompletedMentoringPackages.length - 1].ended_at_date
        return latestCoachingPackage >= latestMentoringPackage
      }
      return false
    },
    canMakeARequestForMentoring() {
      return !this.needsToFinishCoachingFirst
        && this.userPaidMentoringPackages.length > 0
        // eslint-disable-next-line eqeqeq
        && this.userPendingMentoringPackages.length == 0
        // eslint-disable-next-line eqeqeq
        && this.userRunningMentoringPackages.length == 0
        && this.mentor.is_available_for_mentoring
    },
    canMakeARequestForCoaching() {
      return this.userPaidCoachingPackages.length > 0
        // eslint-disable-next-line eqeqeq
        && this.userPendingCoachingPackages.length == 0
        // eslint-disable-next-line eqeqeq
        && this.userRunningCoachingPackages.length == 0
        // eslint-disable-next-line eqeqeq
        && this.userPendingMentoringPackages.length == 0
        // eslint-disable-next-line eqeqeq
        && this.userRunningMentoringPackages.length == 0
        && this.mentor.is_available_for_coaching
        && this.mentor.is_coach
    },
    canMakeARequest() {
      if (this.mentor) {
        return this.isUserLoggedIn
          && (this.mentor.is_available_for_mentoring || this.mentor.is_available_for_coaching)
          && (this.canMakeARequestForMentoring || this.canMakeARequestForCoaching)
      }
      return false
    },
  },
  setup() {
    const {
      userPackages,
      userAwaitingPaymentMentoringPackages,
      userPaidMentoringPackages,
      userPendingMentoringPackages,
      userRunningMentoringPackages,
      userCompletedMentoringPackages,
      userAwaitingPaymentCoachingPackages,
      userPaidCoachingPackages,
      userPendingCoachingPackages,
      userRunningCoachingPackages,
      userCompletedCoachingPackages,
      getUserPackages,
    } = useUserPackagesRepository()

    return {
      userPackages,
      userAwaitingPaymentMentoringPackages,
      userPaidMentoringPackages,
      userPendingMentoringPackages,
      userRunningMentoringPackages,
      userCompletedMentoringPackages,
      userAwaitingPaymentCoachingPackages,
      userPaidCoachingPackages,
      userPendingCoachingPackages,
      userRunningCoachingPackages,
      userCompletedCoachingPackages,
      getUserPackages,
    }
  },
  async created() {
    if (this.isUserLoggedIn) {
      await this.getUserPackages()
      this.packagesInfoRetrieved = true
    }
  },
  beforeMount() {
    this.$bus.$on('mentorship-request-made', this.getUserPackages)
  },
  beforeDestroy() {
    this.$bus.$off('mentorship-request-made', this.getUserPackages)
  },
  methods: {
    requestMentoring() {
      this.$refs['request-mentoring-modal'].show()
    },
    requestCoaching() {
      this.$refs['request-coaching-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
  .make-request-div {
    background: #8044701a;
  }

  .cta-link {
    text-decoration: underline;
  }
</style>
