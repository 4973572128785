<template>
  <b-modal
    id="request-mentoring-modal"
    ref="request-mentoring-modal"
    footer-class="modal-footer-class"
    centered
    size="lg"
    :ok-title="$t('Submit')"
    ok-only
  >
    <template #modal-title>
      <span class="font-weight-bolder">{{ $t('Answer the following questions in order your mentor be able to know you better') }}</span>
    </template>
    <b-row>
      <b-col>
        <validation-observer ref="request-mentoring-modal-observer">
          <validation-provider
            v-slot="{ errors }"
            name="reasonForMentoringRequest"
            rules="required|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="reason-for-mentoring-request">
                {{ $t('Do you search for mentoring on a specific subject on your job?') }} <required-field />
              </label>
              <b-form-textarea
                id="reason-for-mentoring-request"
                v-model="reasonForMentoringRequest"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="acceptTermsAndConditions"
            :rules="{
              required: {
                allowFalse: false
              }
            }"
            mode="lazy"
          >
            <b-form-checkbox
              v-model="acceptTermsAndConditions"
              value="1"
            >
              {{ $t('I agree to') }}
              <b-link
                :to="{ name: 'mentoring-terms' }"
                target="_blank"
              >
                {{ $t('the terms and conditions of WHEN') }}
              </b-link>
              {{ $t('and') }}
              <b-link
                :to="{ name: 'privacy-policy' }"
                target="_blank"
              >
                {{ $t('the privacy policy') }}
              </b-link>
              {{ $t('as a mentee') }} <required-field /><br>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-checkbox>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
    <template
      #modal-footer
      class="d-flex justify-content-between"
    >
      <required-field-explanation />
      <b-button
        variant="primary"
        @click="requestMentoring"
      >
        {{ $t('Submit') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BLink,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'

export default {
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BLink,
    BModal,
    BRow,
    RequiredField,
    RequiredFieldExplanation,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    mentor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reasonForMentoringRequest: null,
      acceptTermsAndConditions: false,
    }
  },
  created() {
    this.reasonForMentoringRequest = this.$store.getters['user/getUserData'].mentee.reason_for_mentoring
  },
  methods: {
    show() {
      this.$refs['request-mentoring-modal'].show()
    },
    async requestMentoring() {
      this.$refs['request-mentoring-modal-observer'].validate().then(async success => {
        if (success) {
          this.acceptTermsAndConditions = false
          this.$refs['request-mentoring-modal'].hide()
          const payload = {
            mentor_id: this.mentor.id,
            reason_for_mentoring_request: this.reasonForMentoringRequest,
          }

          const mentoringRequested = await this.$store.dispatch('user/requestMentoring', payload)
          if (mentoringRequested) {
            this.$root.$bvToast.toast(this.$t('Mentorship request successfully sent.'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.$bus.$emit('mentorship-request-made')
            this.$store.dispatch('user/me')
          } else {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
#request-mentoring-modal {

  .modal-footer-class {
    justify-content: space-between;
  }

}
</style>
